import Vue from "vue";
import "./components";
import "./app.css";
import jump from "jump.js";

import "lazysizes/plugins/object-fit/ls.object-fit";
import "lazysizes";
import "lazysizes/plugins/respimg/ls.respimg";

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  delimiters: ["${", "}"],
  data() {
    return {
      scroll: -1
    };
  },
  methods: {
    headerJump(e) {
      const opener = document.getElementById("opener");
      const height =
        window.innerHeight > opener.offsetHeight
          ? window.innerHeight
          : opener.offsetHeight;
      const scroll = window.scrollY;
      if (e.target.checked) {
        if (scroll < height) {
          this.scroll = scroll;
          jump(height - scroll, { duration: 500 });
        } else this.scroll = -1;
      } else if (this.scroll > -1) {
        jump(this.scroll - height, { duration: 500 }); // to jump back to where you were before opening the nav
      }
    }
  }
});
