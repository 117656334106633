<template>
  <div style="height: 60rem;" class="bg-white max-h-screen w-full"></div>
</template>
<script>
export default {
  props: {
    vendors: Array,
    block: Object
  },
  mounted() {
    /* eslint-disable */
    this.map = new google.maps.Map(this.$el, {
      center: {
        lat: parseFloat(this.block.lat),
        lng: parseFloat(this.block.lng)
      },
      zoom: parseInt(this.block.zoom)
    });

    this.vendors.forEach(vendor => {
      var marker = new google.maps.Marker({
        map: this.map,
        position: { lat: parseFloat(vendor.lat), lng: parseFloat(vendor.lng) }
      });
      google.maps.event.addListener(marker, "click", function() {
        let infowindow = new google.maps.InfoWindow();
        infowindow.setContent(
          "<div><strong>" +
            vendor.title +
            "</strong><br>" +
            (vendor.street || "") +
            "</div>"
        );
        infowindow.open(this.map, this);
      });
    });
  }
};
</script>
